import React from "react";
import { PieChart, Pie, ResponsiveContainer, Tooltip, Sector } from "recharts";
import { formatCurrency, formatIntegerToMoney } from "../utils/formatCurrency";
import { UnauthorizedCard } from "./UnauthorizedCard";
import { Menu } from "../types/Menu";

export type totalAccounts = {
    name: string,
    value: number,
    cash: number
};

type BankAccountsTotalsPieChartProps = {
    title: string;
    totalBalance?: number;
    showDetailsLink?: boolean;
    activeData: number;
    setActiveData: React.Dispatch<React.SetStateAction<number>>;
    totalAccounts: totalAccounts[];
    user?: any;
    menus?: Menu[];
}

const styleToolTip = {
    background: "#fff",
    border: "1px solid #ccc",
    padding: "5px",
    borderRadius: "5px",
    outline: "none"
};

export function BankAccountsTotalsPieChart({
    title,
    totalBalance,
    showDetailsLink = false,
    activeData,
    setActiveData,
    totalAccounts,
    user,
    menus
}: BankAccountsTotalsPieChartProps) {
    const renderActiveShape = (props: any) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, name } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} style={{ fontSize: '12px', fontWeight: "bold"}}>
              {payload.name}
            </text> */}
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" /> */}
                {/* <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" /> */}
                {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={Math.sign(value) === -1 ? 'red' : 'green'} style={{ fontSize: "11px", fontWeight: "bold" }}>{`${formatCurrency(value)}`}</text> */}
            </g>
        );
    };

    const renderTooltip = (accounts: any, activeData: any) => {
        var account = accounts.find((account: any, index: number) => { return index == activeData })

        return <div style={styleToolTip}>
            <h5>{account?.name}</h5>
            <hr style={{ margin: "5px" }} />
            <p style={{ margin: 0 }}><b>Saldo:</b> {formatCurrency(account?.value ?? 0)}</p>
            {
                account?.cash
                    ?
                    <p style={{ margin: 0 }}><b>Em Caixa:</b> R$ {formatIntegerToMoney(account?.cash)}</p>
                    : <></>
            }
        </div>
    }

    return (
        <div className="card card-custom gutter-b">
            {
                user && menus && !JSON.parse(user.permissions).includes(menus.filter(menu => menu.name == "Dashboard")[0]?.submenus?.filter(submenu => submenu.name == "Saldo Total Por Conta")[0].id) && (
                    <UnauthorizedCard />
                )
            }
            <div className="card-body d-flex flex-column align-items-center justify-content-between flex-wrap">
                <b>{title}</b>
                <ResponsiveContainer width="100%" height={308}>
                    <PieChart width={1000} height={1000}>
                        {/* <Legend
                    height={36}
                    iconType="square"
                    layout="vertical"
                    verticalAlign="middle"
                    iconSize={10}
                    // padding={5}
                    formatter={renderColorfulLegendText}
                /> */}
                        <Tooltip content={() => renderTooltip(totalAccounts, activeData)} />
                        <Pie
                            activeIndex={activeData}
                            activeShape={renderActiveShape}
                            data={totalAccounts}
                            cx="50%"
                            cy="50%"
                            innerRadius={70}
                            outerRadius={100}
                            fill="#8884d8"
                            paddingAngle={0}
                            dataKey="value"
                            onMouseEnter={(data, index) => setActiveData(index)}
                        />
                    </PieChart>
                </ResponsiveContainer>
                {/* <ResponsiveContainer width="100%" height={300}>
                      <PieChart width={1000} height={1000}>
                        <Pie
                          activeIndex={activeData}
                          activeShape={renderActiveShape}
                          data={totalAccounts}
                          cx="50%"
                          cy="50%"
                          innerRadius={70}
                          outerRadius={100}
                          fill="#7fc6e0"
                          dataKey="value"
                          onMouseEnter={(data, index) => setActiveData(index)}
                        />
                      </PieChart>
                    </ResponsiveContainer> */}

                {totalBalance !== undefined && (
                    <h2 style={{ color: totalBalance >= 0 ? 'green' : 'red' }}>{formatCurrency(totalBalance)}</h2>
                )}

                {showDetailsLink && (
                    <a href="/fluxo-de-caixa" className="btn btn-sm btn-outline-light mt-3">Ver detalhes</a>
                )}
            </div>
        </div>
    );
}